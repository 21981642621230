<template>
    <b-modal id="modalSearchVideo" title="映像タグを検索"
             size="lg" ref="videoSearchModal" cancel-title="キャンセル" ok-title="表示"
             scrollable
             class="video-search-modal"
             @ok="submit">
        <b-form @submit.prevent ref="videoEditForm">
            <div class="row">
                <div class="col-4">
                    <b-form-group label="ピッチャー" label-for="pitcher">
                        <b-form-select id="pitcher" v-model="form.pitcher">
                            <b-form-select-option-group v-for="t in teams" :label="t.name" :key="'p-'+t._id">
                                <b-form-select-option v-for="p in pitchers.filter(x=>x.team_id===t._id)" :value="p._id" :key="'p'+p._id">{{ p.uniform_no ? '#'+p.uniform_no:''}} {{p.name}}</b-form-select-option>
                            </b-form-select-option-group>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-4">
                    <b-form-group label="キャッチャー" label-for="catcher">
                        <b-form-select id="catcher" v-model="form.catcher">
                            <b-form-select-option-group v-for="t in teams" :label="t.name" :key="'c-'+t._id">
                                <b-form-select-option v-for="p in catchers.filter(x=>x.team_id===t._id)" :value="p._id" :key="'c'+p._id">{{ p.uniform_no ? '#'+p.uniform_no:''}} {{p.name}}</b-form-select-option>
                            </b-form-select-option-group>
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="col-4">
                    <b-form-group label="バッター" label-for="batter">
                        <b-form-select id="batter" v-model="form.batter">
                            <b-form-select-option-group v-for="t in teams" :label="t.name" :key="'b-'+t._id">
                                <b-form-select-option v-for="p in batters.filter(x=>x.team_id===t._id)" :value="p._id" :key="'b'+p._id">{{ p.uniform_no ? '#'+p.uniform_no:''}} {{p.name}}</b-form-select-option>
                            </b-form-select-option-group>
                        </b-form-select>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-10">
                    <b-form-group label="結果">
                        <div style="max-height: 330px; overflow-y: auto;" class="px-1">
                            <b-form-checkbox-group
                                name="result"
                                value-field="_id"
                                text-field="name"
                                v-model="form.result" :options="results"/>
                        </div>
                    </b-form-group>
                </div>
                <div class="col-2">
                    <b-form-group label="盗塁">
                        <div style="max-height: 330px; overflow-y: auto;" class="px-1">
                            <b-form-checkbox-group
                                name="steal"
                                value-field="_id"
                                text-field="name"
                                v-model="form.steal" :options="steal"/>
                        </div>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b-form-group label="カウント別">
                        <div style="max-height: 330px; overflow-y: auto;" class="px-1">
                            <b-form-checkbox-group
                                name="bsCount"
                                value-field="_id"
                                text-field="name"
                                v-model="form.bsCount" :options="ballCount"/>
                        </div>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group label="打順">
                        <div style="max-height: 330px; overflow-y: auto;" class="px-1">
                            <b-form-checkbox-group
                                name="order"
                                value-field="_id"
                                text-field="name"
                                v-model="form.order" :options="battingOrder"/>
                        </div>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group label="打球性質">
                        <div style="max-height: 330px; overflow-y: auto;" class="px-1">
                            <b-form-checkbox-group
                                name="feature"
                                value-field="_id"
                                text-field="name"
                                v-model="form.feature" :options="feature"/>
                        </div>
                    </b-form-group>
                </div>

            </div>
            <div class="row">
                <div class="col">
                    <b-form-group label="球種">
                        <div style="max-height: 330px; overflow-y: auto;" class="px-1">
                            <b-form-checkbox-group
                                name="balltype"
                                value-field="_id"
                                text-field="name"
                                v-model="form.balltype" :options="pitch_type"/>
                        </div>
                    </b-form-group>
                </div>
                <div class="col">
                    <b-form-group label="走者状況">
                        <div style="max-height: 330px; overflow-y: auto;" class="px-1">
                            <b-form-checkbox-group
                                name="runnerSituation"
                                value-field="_id"
                                text-field="name"
                                v-model="form.runnerSituation" :options="runnerSituationData"/>
                        </div>
                    </b-form-group>
                </div>
            </div>
        </b-form>
        <template #modal-footer>
            <div class="w-100 d-flex">
                <b-button
                    variant="success"
                    @click="exportCSV"
                >
                    CSV出力
                </b-button>
                <div class="flex-grow-1">
                    <div class="col pt-2">球数： {{marks.length}}</div>
                </div>
                <b-button
                    variant="secondary"
                    @click="$refs.videoSearchModal.hide();"
                >
                    キャンセル
                </b-button>
                <b-button
                    variant="primary"
                    class="ml-1"
                    @click="submit"
                >
                    表示
                    <b-spinner small v-show="loading" style="margin-left: 5px;" />
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import VideoSearchMixin from "@/components/VideoSearchMixin";

export default {
    name: 'VideoSearchModal',
    mixins: [VideoSearchMixin]
}
</script>

<style scoped>

</style>
